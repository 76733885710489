let key = {};

let Envname = "production";

if (Envname === "production") {
    console.log("production1");
    key = {
        NetworkId: 56,
        frontUrl: "https://defiqplus.com/",
        Contract: "0x3fd637b478a6f7A6752F197949E97370A14F2CC8",
        TokenContract: "0x55d398326f99059fF775485246999027B3197955",
        DefiQtoken: "0x866E7B8F76CF2435Fde81DEA9FB4693Ba002DF8F",
        defalutAddr: "0xC010e1A9d455f784BcD27e82Ee0aC9B31f24FB5d",
        TokenName: "USDT",
        rpcurl: "https://bsc.publicnode.com",
        bscscan: "https://bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://bscscan.com/tx/",
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        Baseurl : "https://prodapi.defiqplus.com"
    };
} else if (Envname === "demo") {
    key = {
        NetworkId: 97,
        frontUrl: "https://defi-q-plus-frontend-demo.pages.dev/",
        Contract: "0x9947F79F69AFacdbE1c77c6664534558747baBb1",
        TokenContract: "0x9F443A4349fb238A3E3381361fF3AEFcB5C82137",
        DefiQtoken: "0x2773A24dF9E9F120c072676A27C7840D8622EC5B",
        defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
        TokenName: "BUSD",
        rpcurl: "https://bsc-testnet-rpc.publicnode.com",
        bscscan: "https://testnet.bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://testnet.bscscan.com/tx/",
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        Baseurl : "https://defiqplusapi.wearedev.team"
    };

} else {
    key = {
        NetworkId: 97,
        frontUrl: "http://localhost:3000/",
        Contract: "0x9947F79F69AFacdbE1c77c6664534558747baBb1",
        TokenContract: "0x9F443A4349fb238A3E3381361fF3AEFcB5C82137",
        DefiQtoken: "0x2773A24dF9E9F120c072676A27C7840D8622EC5B",
        defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
        TokenName: "BUSD",
        rpcurl: "https://bsc-testnet-rpc.publicnode.com",
        bscscan: "https://testnet.bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://testnet.bscscan.com/tx/",
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        Baseurl: "http://localhost:3040",
    };
}

export default key;
