import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PropagateLoader from "react-spinners/PropagateLoader";
import { bignumber } from "../../helper/Action.js";
import { validPositive } from "../../helper/formatAddr.js";
import {
  getFormatMulticall1,
  getFormatMulticall2,
} from "../../helper/Action.js";
import Web3 from "web3";
import contractAbi from "../../ABI/MLMContract.json";
import { useSelector } from "react-redux";
import config from "../../config/index.js";
import { Multicall } from "ethereum-multicall";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";


import treeImgy1 from "../../assets/images/dashboard/tree_img1.png";
import treeImgy2 from "../../assets/images/dashboard/tree_img2.png";
import treeImgy3 from "../../assets/images/dashboard/tree_img3.png";


import treeImgb1 from "../../assets/images/dashboard/tree_img.png";
import treeImgb2 from "../../assets/images/dashboard/tree_img_1.png";
import treeImgb3 from "../../assets/images/dashboard/tree_img_3.png";


import treeImgp4 from "../../assets/images/dashboard/tree_green.png";


import treeImgGy5 from "../../assets/images/dashboard/tree_gray.png";



const override = {
  // display: "block",
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

function TreeView() {

  const walletConnection = useSelector((state) => state?.walletConnection);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({});
  const [plan, setplan] = useState({});
  const [direct, setDirect] = useState({});
  const [searchload, setsearchload] = useState(false);
  const [searchid, setsearchid] = useState("");
  const [backload, setbackload] = useState(false);
  const [isvalid, setisvalid] = useState(false);
  const [backarray, setbackarray] = useState([]);

  const [AdminId,setAdminId]=useState('')

  useEffect(() => {
    if (walletConnection.address) {
      createGenealogyTree(walletConnection.address);
    }
  }, [walletConnection?.address]);

  useEffect(() => {
    getsearchdata();
  }, [searchid]);

  const getsearchdata = async () => {
    try {
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection?.web3);
        setsearchload(true);
        if (searchid == "") {
          setisvalid(false);
          createGenealogyTree(walletConnection.address);
          setsearchload(false);

          return false;
        }

        var contractcall = new web3.eth.Contract(contractAbi, config.Contract);

        console.log('UserId_____dddddd')

        let UserId_data = await contractcall.methods.users(walletConnection.address).call();


        console.log(UserId_data,'UserId')

        // setAdminId(UserId) 

        let getaddress = await contractcall.methods.userList(searchid).call();
        if (getaddress != "0x0000000000000000000000000000000000000000") {
          setisvalid(false);
          await createGenealogyTree(getaddress);
          setsearchload(false);
        } else {
          setsearchload(false);
          setisvalid(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function createGenealogyTree(currentUser) {
    if (
      currentUser === "0x0000000000000000000000000000000000000000" ||
      !walletConnection.address
    ) {
      return null;
    }
  
    const web3 = new Web3(walletConnection?.web3);
    const contractcall = new web3.eth.Contract(contractAbi, config.Contract);
    try {

      let UserId_data = await contractcall.methods.users(walletConnection.address).call();


        console.log(UserId_data,'UserId')

        setAdminId(UserId_data.id)
      let {ids, plans, userDirects} = await contractcall.methods.getTreeData(currentUser, walletConnection.address).call();

      console.log(ids, plans, userDirects,"saranSaran")
      setUser(ids);
      setplan(plans);
      setDirect(userDirects);
    } catch (error) {
      console.error("Error creating genealogy tree:", error);
      return null;
    }
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  console.log(direct,'direct')

  console.log(AdminId,'ttttt', user[0] ,'directUser')

  return (
    <>
      <Container>
        <div className="dashboard_page dashboard_tree_sec">
          <div className={`dashboard_header ${isExpanded ? 'expanded' : 'not-expanded'}`}>
          <div className="mobile_img_none">
            <img
                src={require("../../assets/images/dashboard/user_icon_2.png")}
                className="img-fluid"
                />
            <h2
              className="h2_tag"
              // data-bs-toggle="collapse"
              // href="#collapseExampletwo"
              // role="button"
              // aria-expanded="false"
              // aria-controls="collapseExample"
            >
              Member <span>Tree</span>
            </h2>
            </div>
            <img
              src={require("../../assets/images/dashboard/arrow.png")}
              className="img-fluid me-5 rotate"
              data-bs-toggle="collapse"
              href="#collapseExampletwo"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={toggleCollapse}
            />
          </div>
          <div className="collapse collapse_content" id="collapseExampletwo">
            <InputGroup className="mb-3 mt-5">
              <Form.Control
                placeholder="Search ID"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="custom-placeholder"
                onChange={(e) => setsearchid(e.target.value)}
                onInput={validPositive}
              />
              <InputGroup.Text id="basic-addon2">
                <img
                  src={require("../../assets/images/dashboard/search.png")}
                  className="img-fluid"
                />
                {searchload && (
                  <i
                    class="fa fa-spinner fa-spin checkspin"
                    style={{ fontSize: "24px" }}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>

            <div>
              <Row>
                <Col lg={12}>
                  <div class="tree">
                    <ul>


                      <li>


                        <a href="javascript:void(0)"  data-tooltip-id="tree_tooltip1">
                          {" "}
                          <img
                            src={ AdminId == user[0]  ? treeImgb1 :  direct[0] ?  treeImgy1 : user[0] != 0 ? treeImgp4 : treeImgGy5 }
                          />
                        </a>
                        <Tooltip id="tree_tooltip1" className="custom_tooltip">
                          <h6 className="mb-0">
                            Level <span>{user[0] == 0 ? 0 :  parseInt(plan[0])+1}</span>
                          </h6>
                        </Tooltip>

                        <p className="mt-1" onClick={()=>setsearchid(user[0])} >ID : {user[0] || 0}</p>
                        <ul className="second_chart">
                          <li>

                            <a href="javascript:void(0)" data-tooltip-id="tree_tooltip2" onClick={()=>setsearchid(user[1])}>
                              <img
                                src={direct[1] ? treeImgy2 : user[1] != 0 ? treeImgp4 : treeImgGy5 }
                              />
                            </a>
                            
                            <Tooltip
                              id="tree_tooltip2"
                              className="custom_tooltip"
                            >
                              <h6 className="mb-0">
                                Level <span>{user[1] == 0 ? 0 :  parseInt(plan[1]) +  1}</span>
                              </h6>
                            </Tooltip>

                            <p className="" >ID : {user[1] || 0}</p>
                            <ul className="third_chart">
                              <li>
                                <a href="javascript:void(0)" data-tooltip-id="tree_tooltip3" onClick={()=>setsearchid(user[3])}>
                                  {" "}
                                  <img
                                    src={direct[3] ? treeImgy3 : user[3] != 0 ? treeImgp4 : treeImgGy5 }
                                  />
                                </a>
                                <Tooltip
                                  id="tree_tooltip3"
                                  className="custom_tooltip"
                                >
                                  <h6 className="mb-0">
                                    Level <span>{user[3] == 0 ? 0 :  parseInt(plan[3]) + 1}</span>
                                  </h6>
                                </Tooltip>


                                <p>ID : {user[3] || 0}</p>
                              </li>
                              <li>

                                <a href="javascript:void(0)" data-tooltip-id="tree_tooltip4" onClick={()=>setsearchid(user[4])}>
                                  {" "}
                                  <img
                                    src={direct[4] ? treeImgy3 : user[4] != 0 ? treeImgp4 : treeImgGy5  }
                                  />
                                </a>
                                <Tooltip
                                  id="tree_tooltip4"
                                  className="custom_tooltip"
                                >
                                  <h6 className="mb-0">
                                    Level <span>{user[4] == 0 ? 0 :  parseInt(plan[4]) + 1}</span>
                                  </h6>
                                </Tooltip>
                                <p>ID : {user[4] || 0}</p>


                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="javascript:void(0)" data-tooltip-id="tree_tooltip5" onClick={()=>setsearchid(user[2])}>
                              {" "}
                              <img
                                src={direct[2] ? treeImgy2 : user[2] != 0 ? treeImgp4 : treeImgGy5 }
                              />
                            </a>
                            <Tooltip
                                  id="tree_tooltip5"
                                  className="custom_tooltip"
                                >
                                  <h6 className="mb-0">
                                    Level <span>{user[2] == 0 ? 0 :  parseInt(plan[2]) + 1}</span>
                                  </h6>
                            </Tooltip>
                            <p>ID : {user[2] || 0}</p>
                            <ul>
                              <ul className="third_chart" >
                                <li>
                                  <a href="javascript:void(0)" data-tooltip-id="tree_tooltip6" onClick={()=>setsearchid(user[5])}>
                                    <img
                                     src={direct[5] ? treeImgy3 : user[5] != 0 ? treeImgp4 : treeImgGy5 }
                                      // src={direct[5] ? treeImgp4 : treeImgp4}
                                    />
                                  </a>
                                  <Tooltip
                                  id="tree_tooltip6"
                                  className="custom_tooltip"
                                >
                                  <h6 className="mb-0">
                                    Level <span>{user[5] == 0 ? 0 :  parseInt(plan[5]) + 1}</span>
                                  </h6>
                                  </Tooltip>
                                  <p>ID : {user[5] || 0}</p>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" data-tooltip-id="tree_tooltip7" onClick={()=>setsearchid(user[6])}>
                                    <img
                                     src={direct[6] ? treeImgy3 : user[6] != 0 ? treeImgp4 : treeImgGy5}
                                      
                                    />
                                  </a>
                                  <Tooltip
                                  id="tree_tooltip7"
                                  className="custom_tooltip"
                                  >
                                  <h6 className="mb-0">
                                    Level <span>{user[6] == 0 ? 0 : parseInt(plan[6]) + 1}</span>
                                  </h6>
                                  </Tooltip>
                                  <p>ID : {user[6] || 0}</p>
                                </li>
                              </ul>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default TreeView;
